<template>
  <div class="container">
    <iframe
      width="100%"
      height="100%"
      frameborder="0"
      scrolling="auto"
      :src="privacyPolicy"
      >浏览器版本过低</iframe
    >
  </div>
</template>

<script>
import { privacyPolicy } from "@/config/request";
export default {
  data() {
    return { privacyPolicy };
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
}
</style>
